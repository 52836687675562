(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

require('./autoreg.js');

$(document).ready(function () {
  $('article').prependTo($('.main-text'));
  $('article h1').appendTo($('.for-h'));

  // burger button
  $('.burger').on('click', function () {
    $(this).toggleClass('burger_active');

    if (parseInt($(window).width()) <= 1200) {
      $(this).parents('.header').find('.header__nav').slideToggle();
    }
  });

  $(window).on('resize', function () {
    if (parseInt($(window).width()) > 1200) {
      $('.header').find('.header__nav').css('display', 'flex');
      $('.burger').addClass('burger_active');
    }
  });

  // dropdown menu
  $('.header__menu .sub-menu').parent().addClass('has-children');

  if (parseInt($(window).width()) <= 1200) {
    $('.header__menu .menu__nav .has-children').each(function () {
      var elem = $(this).find(':first');
      var clone = elem.clone();
      var submenu = $(this).find('.sub-menu');

      clone.prependTo(submenu);
      elem.attr('href', 'javascript:void(0);');
    });

    $('.header__menu .menu__nav .nav-item.has-children').click(function () {
      $(this).find('.sub-menu').toggle();
    });
  }

  //COLOR PICKER

  //finds background color of a images
  function rgbToHex(r, g, b) {
    return (r << 16 | g << 8 | b).toString(16);
  }

  /**
   * Takes color from the image and fills the background of the parent
   *
   * @param {string} elem - picture
   */
  function colorPicker(elem) {
    var newImg = new Image();
    var canvas = elem.parents('.logo-bookmaker').find('.canvas_picker').get(0);
    var ctx = canvas.getContext('2d');

    if (elem.hasClass('lazy')) {
      newImg.src = $(elem).attr('data-src');
    } else if ($(elem).attr('data-lazy')) {
      newImg.src = $(elem).attr('data-lazy');
    } else {
      newImg.src = elem.src;
    }

    newImg.onload = function () {
      ctx.drawImage(newImg, 0, 0);

      var dataImg = dataImg = ctx.getImageData(1, 1, 1, 1).data;
      var hex = "#" + ("000000" + rgbToHex(dataImg[0], dataImg[1], dataImg[2])).slice(-6);

      elem.parents('.logo-bookmaker').css('backgroundColor', hex);
    };
  }

  // lazy load
  $('.lazy').lazy({
    effect: 'fadeIn',
    beforeLoad: function beforeLoad(element) {
      colorPicker(element);
    }
  });

  // img.forEach(img => {
  //   setTimeout(() => {
  //     if (img.complete) {
  //       colorPicker('logo-bookmaker', '.canvas_picker');
  //     } else {
  //       img.addEventListener('load', colorPicker);
  //       img.addEventListener('error', function () {
  //         console.error('ERROR: Something was wrong!')
  //       });
  //     }
  //   }, 100);
  // });

  // trim breadcrumbs string length
  function trimLength(elem, length) {

    $(elem).each(function () {
      if ($(this).text().length >= length) {
        $(this).text($(this).text().substr(0, length) + '...');
      }
    });
  }

  trimLength('[itemprop="name"]', 30);
  trimLength('.kb_title', 30);

  // readMore button
  $('.bk-post__button>button').each(function () {
    $(this).click(function (e) {
      e.preventDefault();
      var text = $(this).parents('.bk-post').find('.bk-post__text');

      $(this).toggleClass('open');

      if ($(this).hasClass('open')) {
        $(this).text('hide');
      } else {
        $(this).text('read more');
      }

      if (text.css('max-height') == '120px') {
        text.css('max-height', '100%');
      } else {
        text.css('max-height', '120px');
      }
    });
  });

  // equal height for cards
  $.fn.equivalent = function () {
    var $blocks = $(this),
        maxH = $blocks.eq(0).height(),
        maxBlock = void 0;

    $blocks.each(function () {
      maxH = $(this).height() > maxH ? $(this).height() : maxH;

      if ($(this).height() == maxH) {
        maxBlock = $(this);
      }
    });

    var growValue = 0;

    $blocks.each(function () {
      var maxEach = $(this).height();
      var withoutBlock = maxEach - $(this).find('.card__bonus').height();

      growValue = maxH - withoutBlock;

      $(this).find('.card__bonus').css('min-height', growValue);
    });
  };

  function bonusHeight() {
    if ($(window).width() <= 1200 && $(window).width() >= 650) {
      $('.card').each(function () {
        $(this).find('.card__bonus').css({
          'min-height': 'auto'
        });
      });
      $('.card').equivalent();
    } else {
      $('.card').each(function () {
        $(this).find('.card__bonus').css({
          'min-height': 'auto'
        });
      });
    }
  }

  $('.card').ready(function () {
    bonusHeight();
    $(window).on('resize', function () {
      bonusHeight();
    });
  });

  // UP button - scroll to the top
  var btn = $('.back-top');

  $(window).scroll(function () {
    if ($(window).scrollTop() > 1000) {
      btn.show();
    } else {
      btn.hide();
    }
  });

  btn.on('click', function (e) {
    e.preventDefault();
    $('html, body').animate({
      scrollTop: 0
    }, 'slow');
  });

  // exit popup
  $('.popup__close').click(function () {
    $(this).parents('.popup').fadeOut();
    $(this).parents('.popup').next().fadeOut();
    $('body').css('overflow', '');
  });

  $('.overlay').click(function () {
    $(this).fadeOut();
    $(this).prev().fadeOut();
    $('body').css('overflow', '');
  });

  // if (parseInt($(window).width()) > 900) {
  //   $(document).mouseleave(function (e) {

  //     if ($('.popup').data('show') == 'show') {
  //       // Show the exit popup
  //       $('.overlay').fadeIn();
  //       $('.popup').fadeIn();
  //       $('.popup').data('show', 'hide');
  //       $('body').css('overflow', 'hidden');
  //     }

  //   });
  // }

  function subscribePopup() {
    $('.popup').css('padding', '100px');

    $('.popup').find('.popup__title').text('ВЫ УСПЕШНО ПОДПИСАЛИСЬ!');
    $('.popup').find('.popup__title').css('margin-bottom', '0');

    $('.popup').find('.popup__descr').remove();
    $('.popup').find('.form').remove();

    $('.popup').find('.popup__subscribe').show();
  }

  $('.form').submit(function () {
    var form = $(this);
    var hint = form.find('.form__input_hint');
    var field = [];

    form.find('input[data-validate]').each(function () {
      field.push('input[data-validate]');

      var line = $(this).closest('.form__input'),
          value = $(this).val();

      if (!value) {
        line.addClass('form__input_required');
        hint.css('opacity', '1');

        event.preventDefault();
        return false;
      } else {
        subscribePopup();
        event.preventDefault();
      }
    });
  });

  $('.comment-text > ul:last-of-type').empty();

  // Sliders
  $('.cs-slider').slick({
    centerMode: true,
    infinite: true,
    slidesToShow: 4,
    arrows: true,
    dots: false,
    responsive: [{
      breakpoint: 1632,
      settings: {
        centerMode: false,
        slidesToShow: 3
      }
    }, {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2
      }
    }, {
      breakpoint: 701,
      settings: {
        slidesToShow: 1
      }
    }]
  });

  $('.cs-slider').on('lazyLoaded', function (event, slick, image, imageSource) {
    colorPicker(image);
  });
});

},{"./autoreg.js":2}],2:[function(require,module,exports){
'use strict';

$('[data-link]').click(function () {
    var link = $(this).data('link');
    window.open('/' + atob(link), '_self');
});

},{}]},{},[1]);
